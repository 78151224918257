import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../misc/api";
import Loading from "../../common/Loading";
import { mapItems } from "../../../misc/utils";
import { SubHeading } from "../../styled/styledTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BookingModal({ isOpen, handleClose, id }) {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, index) => {
    const _items = items;
    _items[index].booked = e.target.value;
    setItems([...items]);
  };

  const getItems = () => {
    setIsLoading(true);
    api
      .post("items/all")
      .then((res) => {
        const { success, data } = res.data;

        if (success) {
          const _items = mapItems(data);
          setItems([..._items]);
        }
      })

      // TODO: error handling

      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getItems(), []);

  const handleSave = () => {
    const url = "collections/book-a-collection";
    setIsLoading(true);
    api.post(url, { id, items }).then((res) => {
      const { success, data } = res.data;
      if (success) {
        window.location.reload();
      }
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-booking"
      aria-describedby="modal-booking-for-collection"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={style}>
          <SubHeading sx={{ mb: 2 }}>
            What items need to be collection?{" "}
          </SubHeading>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            {items.map((item, index) => (
              <TextField
                name={item}
                label={item?.name + " Quantity"}
                type="number"
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              columnGap: 4,
            }}
          >
            <Button
              fullWidth
              onClick={handleClose}
              color="error"
              variant="contained"
            >
              Close
            </Button>
            <Button
              fullWidth
              onClick={handleSave}
              color="success"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
}
