import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Heading, SubHeading } from "../../styled/styledTypography";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../misc/api";
import { camelCaseToWords } from "../../../misc/utils";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";

const filter = createFilterOptions();
export default function Destruction() {
  const location = useLocation();
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState({});
  const [collectionDropdown, setCollectionDropdown] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [collectionList, setCollectionList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state.id) return;

    setLoading(true);

    api
      .post(`/collections/id`, { id: location.state.id })
      .then((res) => {
        const _data = res.data.data;
        setCollection(_data);
        setLoading(false);
      })
      .catch((e) => {});
  }, [location]);

  const onChangeMethod = (e) => {
    setMethods({
      ...methods,
      [e.target.name]: e.target.value,
    });
  };

  const handleGenerate = () => {
    api
      .post("/mail/pdf/destruction", {
        id: collection._id,
        methods,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate("/dashboard/collections/" + collection._id);
        }
      });
  };

  const onCollectionChange = async (ref = null) => {
    setCollectionLoading(true);
    await api.post("/collections/search", { ref }).then((res) => {
      setCollectionList(res.data.data);
      setCollectionLoading(false);
    });
  };

  useEffect(() => {
    console.log("Methods Now", methods);
  }, [methods]);

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Heading>Generate Certificate of Destruction</Heading>
      {loading ? (
        <Heading>Loading ... </Heading>
      ) : (
        <Box
          sx={{
            border: "1px solid #ccc",
            borderTop: "none",
            borderBottom: "none",
            marginTop: 2,
            p: 2,
          }}
        >
          {/* <Autocomplete
            id="collection-dropdwon"
            sx={{ width: 300 }}
            open={collectionDropdown}
            onOpen={() => {
              setCollectionDropdown(true);
            }}
            onClose={() => {
              setCollectionDropdown(false);
            }}
            isOptionEqualToValue={(option, value) => option.ref === value.ref}
            getOptionLabel={(option) => option.ref}
            options={collectionList}
            autoComplete
            includeInputInList
            filterSelectedOptions
            loading={collectionLoading}
            value={collection}
            onChange={(event, newValue) => {
              setCollectionList(
                newValue ? [newValue, ...collectionList] : collectionList
              );
              setCollection(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              onCollectionChange(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Collection"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          /> */}

          <Grid container>
            <Grid xs={2}>
              <SubHeading>Company Name:</SubHeading>
              <Typography>{collection?.companyName}</Typography>
            </Grid>
            <Grid xs={2}>
              <SubHeading>Contact Name:</SubHeading>
              <Typography>{collection?.contactName}</Typography>
            </Grid>
            <Grid xs={4}>
              <SubHeading>Address:</SubHeading>
              <Typography>
                {collection?.address}, {collection?.city}, {collection?.county}{" "}
                {collection?.postCode}
              </Typography>
            </Grid>
            <Grid xs={2}>
              <SubHeading>Booked On:</SubHeading>
              <Typography>
                {moment(collection?.bookedOn).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid xs={2}>
              <SubHeading>Collected On:</SubHeading>
              <Typography>
                {moment(collection?.scheduledOn).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>

          {collection && (
            <Box sx={{ mt: 5 }}>
              <SubHeading>Collected Items</SubHeading>
              {(collection?.items)
                .filter((c) => c.collected > 0)
                .map((item) => (
                  <Box
                    sx={{
                      mt: 1,
                      p: 1,
                      background: item.collected < 1 ? "#EBEBE4" : "inherit",
                    }}
                  >
                    <Typography
                      sx={{
                        color: item.collected < 1 ? "#ccc" : "inherit",
                        fontWeight: "bold",
                      }}
                    >
                      {item.name} (Collected Quantity: {item.collected})
                    </Typography>

                    <Autocomplete
                      name={item.name}
                      value={methods[item.name]}
                      disabled={item.collected < 1}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setMethods({
                            ...methods,
                            [item.name]: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setMethods({
                            ...methods,
                            [item.name]: newValue.inputValue,
                          });
                        } else {
                          setMethods({
                            ...methods,
                            [item.name]: newValue,
                          });
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        console.log(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={["HMG Infosec 5 (IS5) standard", "Crushing"]}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option}</li>
                      )}
                      freeSolo
                      renderInput={(params) => <TextField {...params} />}
                    />

                    {/* <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name={objKey}
                      value={methods[objKey]}
                      onChange={onChangeMethod}
                      disabled={collection?.items[objKey] < 1}
                    >
                      <MenuItem value={0}>
                        HMG Infosec 5 (IS5) standard
                      </MenuItem>
                      <MenuItem value={1}>Crushing</MenuItem>
                      <MenuItem value={2}>HMG Infosec 6 (IS5) free</MenuItem>
                    </Select>
                  </FormControl> */}
                  </Box>
                ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 3,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleGenerate}
                  disabled={
                    (collection?.items).filter((c) => c.collected > 0).length <
                    1
                  }
                >
                  Generate
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
