import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  TablePagination,
  Tooltip,
} from "@mui/material";
import {
  AddCircleOutline,
  AirportShuttle,
  Bookmark,
  CheckCircle,
  Delete,
  DeleteForever,
  Info,
  Inventory,
  Mail,
  QuestionMark,
  Schedule,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import Loading from "../../../common/Loading";
import colours from "../../../../misc/colours";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  {
    id: "reference",
    sort: true,
    numeric: false,
    disablePadding: true,
    label: "Reference",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "postCode",
    numeric: false,
    disablePadding: false,
    label: "Post Code",
  },
  {
    id: "bookedOn",
    numeric: false,
    disablePadding: false,
    label: "Booked On",
  },
  {
    id: "scheduledOn",
    numeric: false,
    disablePadding: false,
    label: "Scheduled On",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "invoiceStatus",
    number: false,
    disablePadding: false,
    label: "Invoice Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, handleDelete } = props;

  return (
    <Box sx={{ paddingTop: numSelected > 0 ? 0 : 2 }}>
      {numSelected > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {numSelected > 0 && (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          )}

          {numSelected > 0 && (
            <Tooltip title="Delete">
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </Box>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ManageTable({
  rows,
  deleteAction,
  openDetails,
  loading,
  scheduleAction,
  handleSendQuote,
  totalRows,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  totalPages,
  totalItems,
  bookingAction,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const emptyRows = 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    //eslint-disable-next-line
    [order, orderBy, page, rowsPerPage]
  );

  const handleDelete = () => {
    deleteAction(selected);
  };

  const onSendQuoteClicked = (data) => {
    handleSendQuote(data);
  };

  const getRowStatus = (status) => {
    var icon = "";
    switch (status) {
      case 0:
        icon = (
          <Tooltip title="Booked / Waiting">
            <Bookmark color="info" />
          </Tooltip>
        );
        break;
      case 1:
        icon = (
          <Tooltip title="Scheduled">
            <AirportShuttle color="warning" />
          </Tooltip>
        );
        break;
      case 2:
        icon = (
          <Tooltip title="Collected">
            <Inventory color="success" />
          </Tooltip>
        );
        break;
      case 3:
        icon = (
          <Tooltip title="Invoiced">
            <CheckCircle color="success" />
          </Tooltip>
        );
        break;
      default:
        icon = <QuestionMark color="error" />;
        break;
    }
    return icon;
  };

  const getInvoiceStatus = (row) => {
    // 0 = No Invoice , 1 = Invoiced, not paid, 2 = Paid
    // Overdue if Invoice date is greater than 28 days;
    const invoice = row?.certs?.invoice;
    const fee = row?.fee;

    if (fee === 0) {
      return "Free Collection";
    }

    if (invoice?.status === 0) {
      return "Not Generated";
    }

    if (invoice?.status === 1) {
      return "Invoiced " + (row?.invoiceOverdue ? "( Overdue )" : "");
    }

    if (invoice?.status === 2) {
      return (
        "Invoice Paid on " +
        moment(invoice?.invoicePaidDate).format("DD / MM / YYYY")
      );
    }
  };

  React.useEffect(() => {
    console.log("data: => ", { rows, page, rowsPerPage });
  }, [rows, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDelete={handleDelete}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows?.length === 0 && (
                <TableRow hover>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}

              {loading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                rows?.map((row) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = row._id;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row._id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.ref}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.postCode}</TableCell>
                      <TableCell>
                        {moment(row?.bookedOn).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {row?.scheduledOn ? (
                          moment(row?.scheduledOn).format("DD-MM-YYYY")
                        ) : (
                          <Typography component={"span"} color="error">
                            To Be Scheduled
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>{getRowStatus(row?.status)}</TableCell>
                      <TableCell>{getInvoiceStatus(row)}</TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            columnGap: 2,
                          }}
                        >
                          {/* {(row?.status === 6 || row?.status === 5) && (
                            <Tooltip title="Book Collection">
                              <AddCircleOutline
                                onClick={() => bookingAction(row?._id)}
                                sx={(theme) => ({
                                  color: colours.secondaryColor,
                                  ":hover": {
                                    color: "#CD1818",
                                  },
                                })}
                              />
                            </Tooltip>
                          )} */}

                          {row?.status === 0 && (
                            <Tooltip title="Schedule">
                              <Schedule
                                onClick={() => scheduleAction(row?._id)}
                                sx={(theme) => ({
                                  color: colours.secondaryColor,
                                  ":hover": {
                                    color: "#CD1818",
                                  },
                                })}
                              />
                            </Tooltip>
                          )}
                          {row?.status === 6 && (
                            <Tooltip title="Send Quote">
                              <Mail
                                onClick={() => onSendQuoteClicked(row?._id)}
                                sx={(theme) => ({
                                  color: "green",
                                  ":hover": {
                                    color: "#CD1818",
                                  },
                                })}
                              />
                            </Tooltip>
                          )}
                          <Tooltip title="Details">
                            <Info
                              sx={(theme) => ({
                                color: "#337CCF",
                                ":hover": {
                                  color: "#0C356A",
                                },
                              })}
                              onClick={(_) => openDetails(row?._id)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteForever
                              sx={(theme) => ({
                                color: "#FF6666",
                                ":hover": {
                                  color: "#CD1818",
                                },
                              })}
                              onClick={(_) => deleteAction(row?._id)}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => onPageChange(newPage)}
          onRowsPerPageChange={(event) =>
            onRowsPerPageChange(parseInt(event.target.value, 10))
          }
        />
      </Paper>
    </Box>
  );
}
